<template>

  <b-button
    v-if="isManagedUser(user)"
    :id="user?`manage${user.referral_id}`:''"
    variant="danger"
    :class="short?'rounded-circle btn-icon':'btn-cart'"
    @click="switchToUser(user)"
  >
    <feather-icon
      icon="LogInIcon"
      :class="short?'':'mr-50'"
      :size="short?'22':'16'"
    />
    <span v-if="!short">{{ $t('LogIn') }}</span>
  </b-button>

</template>

<script>
import {
  VBTooltip, BButton,
} from 'bootstrap-vue'
import { useUserUi } from '@/views/apps/user/useUser'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    short: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  setup() {
    const { switchToUser, isManagedUser } = useUserUi()

    return {
      switchToUser, isManagedUser,
    }
  },
}
</script>
