<template>
  <div>
    <b-overlay
      :show="fetchingReferrals"
      variant="white"
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="(referral, key) in referralsList"
          :key="key"
        >

          <b-row>
            <b-col
              cols="12"
              sm="4"
              align-self="center"
            >
              <div
                class="d-flex justify-content-start align-items-center mb-1"
              >
                <!-- avatar -->
                <b-avatar
                  button
                  size="32"
                  variant="dark"
                  :src="('avatar'in referral)?referral.avatar:null"
                  class="ml-1 mr-1"
                />
                <!--/ avatar -->
                <div class="profile-user-info">
                  <h6 class="mb-0">
                    {{ referral.name }}
                  </h6>
                  <small class="text-muted">{{ referral.created_at | formatDateTime }}</small>
                </div>
              </div>

            </b-col>

            <b-col
              cols="12"
              sm="4"
              align-self="center"
            >
              <div
                v-for="(serviceOffer, index) in referral.service_offers"
                :key="index"
              >
                <b-badge
                  v-for="purpose in serviceOffer.point_purposes"
                  :key="purpose.slug"
                  pill
                  variant="light-primary"
                >
                  {{ getPurposeTitle(purpose.slug) }}
                </b-badge>
              </div>

            </b-col>
            <b-col
              cols="12"
              sm="4"
              align-self="center"
            >
              <div class="d-flex justify-content-center align-items-center ">
                <b-tooltip
                  v-if="isUserServiceProvider(referral)"
                  :target="`share${referral.referral_id.toString()}`"
                  placement="top"
                  triggers="click"
                >
                  {{ $t('common.link_copied') }}
                </b-tooltip>
                <b-button
                  v-if="isUserServiceProvider(referral)"
                  :id="`share${referral.referral_id.toString()}`"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle m-1"
                  @click="copyServiceProviderProfileLink(referral.referral_id)"
                >
                  <feather-icon
                    icon="Share2Icon"
                    size="22"
                  />
                </b-button>
                <user-switcher :user="referral" />
              </div>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BAvatar,
  BBadge,
  BButton,
  BTooltip,
  VBTooltip,

} from 'bootstrap-vue'

import store from '@/store'
import { serviceOptions } from '@/mixins/options'
import Ripple from 'vue-ripple-directive'
import { useUserUi } from '@/views/apps/user/useUser'
import { formatDateTime } from '@/filters/dateTime'
import { useReferral } from '@/views/apps/referral/useReferral'
import UserSwitcher from '@/layouts/components/app-navbar/components/UserSwitcher.vue'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BBadge,
    BButton,
    BTooltip,
    UserSwitcher,
  },
  filters: {
    formatDateTime,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {

    }
  },
  computed: {
    referralsList() {
      return store.getters['referral/referrals']
    },
  },

  methods: {

    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip(id) {
      this.$root.$emit('bv::hide::tooltip', id)
    },
    copyServiceProviderProfileLink(referralId) {
      navigator.clipboard.writeText(this.getServiceProviderProfileLink(referralId))
        .then(
          () => {
            this.showTooltip(referralId.toString())
            setTimeout(code => {
              this.hideTooltip(code)
            }, 1000)
          },
          () => {
            /* clipboard write failed */
          },
        )
    },
  },

  setup() {
    const { getPurposeTitle } = serviceOptions
    const { getServiceProviderProfileLink, isUserServiceProvider, manageUser } = useUserUi()
    const { fetchingReferrals, fetchReferrals } = useReferral()

    fetchReferrals()

    return {
      getPurposeTitle,
      isUserServiceProvider,
      getServiceProviderProfileLink,
      fetchReferrals,
      fetchingReferrals,
      manageUser,
    }
  },
}

</script>
